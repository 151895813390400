import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Menu } from '@app/shared/model/menu.model';
import { Store } from '@ngrx/store';
import { BaseWebService } from 'app/core/services/base-web.service';
import { catchError, map, Observable, of, switchMap } from 'rxjs';
import { setMenu } from './menu.action';

@Injectable()
export class MenuService extends BaseWebService {
  private readonly menu$: Observable<Menu>;

  constructor(private http: HttpClient, private store: Store<{ menu: Menu }>) {
    super('identity/menu', http);
    this.menu$ = this.store.select('menu');
  }

  getUserMenu() {
    return this.menu$.pipe(this.checkAndGetIfNull());
  }

  forceReolad() {
    this.getData().subscribe((data) => {
      this.store.dispatch(setMenu({ menu: data }));
    });
  }

  private getData() {
    return this.getMethod<Menu>('');
  }

  private checkAndGetIfNull(): (source$: Observable<Menu>) => Observable<Menu> {
    return (source$: Observable<Menu>) =>
      source$.pipe(
        switchMap((value) => {
          if (value?.menuItems.length !== 0) {
            return of(value);
          } else {
            return this.getData().pipe(
              map((data) => {
                this.store.dispatch(setMenu({ menu: data }));
                return data;
              }),
              catchError((error) => {
                console.error('Error fetching data:', error);
                return of(value);
              })
            );
          }
        })
      );
  }
}
