import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseWebService } from '@app/core/services/base-web.service';

@Injectable({
  providedIn: 'root',
})
export class ConfigUserService extends BaseWebService {
  constructor(private http: HttpClient) {
    super('dashboard/ConfigUser', http);
  }

  getConfigUser(key: string) {
    return this.getMethod<{ Key: string; value: any }>(key);
  }

  setConfigUser(key: string, value: any) {
    return this.postMethod('', { Key: key, Value: value });
  }
}
