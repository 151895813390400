import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as uuid from 'uuid';
@Component({
  selector: 'app-custom-datepicker',
  templateUrl: './custom-datepicker.component.html',
  styleUrls: ['./custom-datepicker.component.scss'],
})
export class CustomDatepickerComponent implements OnInit {
  @Input('placeholder') placeholder: string = '';
  @Input('formControl') formControl: FormControl;
  @Input('customClass') customClass: string;
  @Input('label') label: string;
  @Input('id') id: string = uuid.v4();
  @Input('disabled') disabled: boolean = false;

  protected hasErrors: boolean = false;

  ngOnInit() {
    this.formControl.valueChanges.subscribe((value) => {
      this.hasErrors = false;
      if (!!this.formControl.errors) {
        this.hasErrors = true;
      }
    });
  }
}
