export enum FeatureFlagEnum {
  Base,
  MakorAPI_Country_6079,
  MakorAPI_SubPrograms_6082,
  MakorAPI_GetPrograms_DivisionByProgram_6057_6080,
  MakorAPI_Programs_options_6056,
  MakorAPI_GetAllDivision_6081,
  MakorAPI_StatesOrProvinces_6084,
  MakorAPI_Divisions_6055,
  Subcontractors_Feature_6466,
  ExpectedMaterials_6762,
  Assets_6486_6471,
}
