import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from 'services/auth-guard/auth-guard.service';
import { AuthRedirectService } from 'services/auth-guard/auth-redirect.service';
import { RoleService } from './core/services/role.service';
import { FeatureFlagService } from './shared/FeatureFlags/services/feature-flag.service';
import { ReceivingReportComponent } from './modules/report-viewer/components/receiving-report/receiving-report.component';
import { MateriaValidationReportComponent } from './modules/report-viewer/components/materia-validation-report/materia-validation-report.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full',
    //canActivate: [AuthGuardService],
    // loadChildren: () =>
    //   import('./modules/home/home.module').then((mod) => mod.HomeModule)
  },
  {
    path: 'home',
    canActivate: [AuthGuardService, RoleService, FeatureFlagService],
    loadChildren: () =>
      import('./modules/home/home.module').then((mod) => mod.HomeModule),
  },
  {
    path: 'login',
    canActivate: [AuthRedirectService],
    loadChildren: () =>
      import('./modules/login/login.module').then((mod) => mod.LoginModule),
  },
  {
    path: 'temporaryauth/:hash/:userId',
    canActivate: [AuthRedirectService],
    loadChildren: () =>
      import('./modules/temporary-auth/temporary-auth.module').then(
        (mod) => mod.TemporaryAuthModule
      ),
  },
  {
    path: 'registration-link',
    canActivate: [AuthRedirectService],
    loadChildren: () =>
      import('./modules/registration-link/registration-link.module').then(
        (mod) => mod.RegistrationLinkModule
      ),
  },
  {
    path: 'not-found',
    canActivate: [],
    loadChildren: () =>
      import('./modules/not-found/not-found.module').then(
        (mod) => mod.NotFoundModule
      ),
  },
  {
    path: 'report-viewer',
    loadChildren: () =>
      import('./modules/report-viewer/report-viewer.module').then(
        (mod) => mod.ReportViewerModule
      ),
  },
  {
    path: '**',
    canActivate: [],
    loadChildren: () =>
      import('./modules/not-found/not-found.module').then(
        (mod) => mod.NotFoundModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
