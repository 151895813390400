import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseWebService } from '@app/core/services/base-web.service';
import { AssetDto } from '../model/asset-dto.model';
import { AssetsRequest } from '../model/asset-request.model';
import { PagedList } from '@app/core/models/paged-list.model';
import { AssetReport } from '@app/modules/orders/models/asset-report';
import { CustomSelectOption } from '../components/input/custom-select/models/custom-select-option';

@Injectable()
export class AssetSourceService extends BaseWebService {
  constructor(private http: HttpClient) {
    super('makorsource/Assets', http);
  }

  public GetAssets(reuqest: AssetsRequest) {
    return this.postMethod<PagedList<AssetDto>>('', reuqest);
  }

  public getReportsList(assetId: number) {
    return this.getMethod<AssetReport[]>(`Reports/${assetId}`);
  }

  public getOrderId(assetId: number) {
    return this.getMethod<number>(`OrderId/${assetId}`);
  }

  public GetAssetsBySerialNumbers(search: string) {
    return this.getMethod<CustomSelectOption[]>(
      `SerialNumbers?search=${search}`
    );
  }
}
