<div [formGroup]="form" *ngIf="show">
  <div [ngSwitch]="question.controlType">
    <app-custom-input
      [label]="question.label"
      *ngSwitchCase="controlType.TextBox"
      ngDefaultControl
      [id]="question.key"
      [formControl]="this.formControl"
    ></app-custom-input>

    <app-custom-select
      [label]="question.label"
      [id]="question.key"
      *ngSwitchCase="controlType.DropDown"
      ngDefaultControl
      [formControlName]="question.key"
      [options]="question.options"
      [formControl]="this.formControl"
    ></app-custom-select>

    <app-custom-datepicker
      [label]="question.label"
      [id]="question.key"
      ngDefaultControl
      *ngSwitchCase="controlType.DatePicker"
      [formControl]="this.formControl"
    ></app-custom-datepicker>

    <app-custom-multi-select
      [label]="question.label"
      [id]="question.key"
      ngDefaultControl
      [options]="question.options"
      *ngSwitchCase="controlType.MultiSelect"
      [formControl]="this.formControl"
    ></app-custom-multi-select>

    <app-custom-datepicker-range
      ngDefaultControl
      *ngSwitchCase="controlType.DateRangePicker"
      [label]="question.label"
      [id]="question.key"
      [formGroup]="this.formGroup"
    ></app-custom-datepicker-range>
  </div>

  <!-- <div class="errorMessage" *ngIf="!isValid">{{question.label}} is required</div> -->
</div>
