import { CustomSelectOption } from '../components/input/custom-select/models/custom-select-option';

export class QuestionBase<T> {
  value: T | undefined;
  key: string;
  label: string;
  required: boolean;
  order: number;
  controlType: number;
  type: string;
  options: CustomSelectOption[];
  id: number;

  constructor(
    options: {
      value?: T;
      key?: string;
      label?: string;
      required?: boolean;
      order?: number;
      controlType?: number;
      type?: string;
      options?: CustomSelectOption[];
      id?: number;
    } = {}
  ) {
    this.value = options.value;
    this.key = options.key || '';
    this.label = options.label || '';
    this.required = !!options.required;
    this.order = options.order === undefined ? 1 : options.order;
    this.controlType =
      options.controlType === undefined ? 0 : options.controlType;
    this.type = options.type || '';
    this.options = options.options || [];
    this.id = options.id || 0;
  }

  static prepareByType(
    controlType: number,
    options: {
      value?: any;
      key?: string;
      label?: string;
      required?: boolean;
      order?: number;
      controlType?: number;
      type?: string;
      options?: CustomSelectOption[];
      id?: number;
    } = {}
  ) {
    switch (controlType) {
      case ControlType.DropDown:
        return new DropdownQuestion(options);

      case ControlType.DatePicker:
        return new DateQuestion(options);

      case ControlType.MultiSelect:
        return new MultiSelectQuestion(options);

      case ControlType.DateRangePicker:
        return new DateRangeQuestion(options);

      default:
        return new TextboxQuestion(options);
    }
  }
}

export class TextboxQuestion extends QuestionBase<string> {
  override controlType = ControlType.TextBox;
}

export class DropdownQuestion extends QuestionBase<string> {
  override controlType = ControlType.DropDown;
}

export class DateQuestion extends QuestionBase<string> {
  override controlType = ControlType.DatePicker;
}

export class MultiSelectQuestion extends QuestionBase<string> {
  override controlType = ControlType.MultiSelect;
}

export class DateRangeQuestion extends QuestionBase<string> {
  override controlType = ControlType.DateRangePicker;
}

export enum ControlType {
  TextBox = 1,
  DropDown = 2,
  DatePicker = 3,
  MultiSelect = 4,
  DateRangePicker = 5,
}
