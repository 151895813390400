import { OnInit, Input, ElementRef, Injectable } from '@angular/core';
import { File } from '@app/core/models/file.model';
import { ColumnElementParams } from '@app/shared/components/custom-table/components/column-container/column-element-params';
import { ColumnComponent } from '@app/shared/components/custom-table/components/column-container/column.component';

@Injectable()
export abstract class ReportSelectColumnComponent
  implements ColumnComponent, OnInit
{
  @Input() params: ColumnElementParams;

  options: { id: string; text: string }[] = [];
  id: number = 0;
  loaded: boolean = false;
  downloading: boolean = false;
  selectedOption: string = '';
  tableElement: HTMLElement | null | undefined;

  constructor(protected elRef: ElementRef) {}

  abstract loadList(): void;
  abstract downloadReport(): void;

  ngOnInit(): void {
    this.id = +this.params.data[this.params.params.id];
    this.setDefaultList();
    this.setParentNode();
  }

  protected setParentNode() {
    let nativeElement = this.elRef.nativeElement as
      | HTMLElement
      | null
      | undefined;
    let times = 0;
    while (true) {
      if (nativeElement?.parentElement?.nodeName === 'APP-CUSTOM-TABLE') {
        nativeElement = nativeElement.parentElement;
        break;
      } else {
        nativeElement = nativeElement?.parentElement;
      }
      times++;
      if (times >= 15) {
        break;
      }
    }
    this.tableElement = nativeElement;
  }

  protected setDefaultList(reports: { id: string; text: string }[] = []) {
    this.options = [];
    if (reports.length === 0) {
      this.options.push({ id: '', text: 'Select report' });
      return;
    }

    this.options.push({ id: '', text: 'Select report' });
    this.options.push(...reports);
  }

  protected setLoadingList() {
    this.options = [];
    this.options.push({ id: '', text: 'Loading...' });
  }

  protected selectedReport(obj: any) {
    this.selectedOption = obj.target.value;
    this.tableElement?.scrollTo(this.tableElement?.scrollWidth, 0);
  }

  protected downloadFile(file: File) {
    fetch(`data:plain/text;base64,${file.content}`)
      .then((res) => res.blob())
      .then((docFile) => {
        const objectUrl = URL.createObjectURL(docFile);

        let a = document.createElement('a');
        a.setAttribute('href', objectUrl);
        a.setAttribute(
          'download',
          this.getFileName(`${file.fileName}_${this.id}`, file.ext)
        );
        a.click();
      });
  }

  private getFileName(name: string, type: string): string {
    let fileName = name;
    let ext = type.indexOf('.') < 0 ? '.' + type : type;
    if (String(fileName).lastIndexOf('.') < 0) fileName += ext;

    return fileName;
  }
}
