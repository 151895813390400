export enum ColumnType {
  Text = 1,
  Number = 2,
  Date = 3,
  Progress = 4,
  Link = 5,

  OrderReportSelect = 6,
  Callback = 7,
  Hidden = 8,
  Menu = 9,
  CheckBox = 10,
  Icon = 11,
  AssetReportSelect = 12,
}
