import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseWebService } from '@app/core/services/base-web.service';
import { AssetReportEnum } from '@app/core/enums/asset-report-enum';
import { File } from '@app/core/models/file.model';

@Injectable()
export class AssetApiService extends BaseWebService {
  constructor(private http: HttpClient) {
    super('makorservice/Assets', http);
  }
  getCustomReport(assetId: number, type: AssetReportEnum, orderId: number) {
    return this.getMethod<File>(`${assetId}/reports/${type}/${orderId}`);
  }
}
