import { Component, ElementRef, OnInit } from '@angular/core';
import { CustomInboundOrderReportEnum } from '@app/core/enums/custom-inbound-order-report-enum';
import { InboundOrderReportTypeEnum } from '@app/core/enums/inbound-order-report-type-enum';
import { File } from '@app/core/models/file.model';
import { MakorOrderAPIService } from '@app/modules/orders/services/makor-order-api.service';
import { ReportSelectColumnComponent } from './report-select-column.component';
import { ColumnType } from '../column.type';
import { ColumnDef } from '../column';
import { AssetSourceService } from '@app/shared/services/asset-source.service';
import { AssetReport } from '@app/modules/orders/models/asset-report';
import { AssetApiService } from '@app/shared/services/asser-api.service';
import { AssetReportEnum } from '@app/core/enums/asset-report-enum';

export class AssetReportSelectColumnDef extends ColumnDef {
  constructor(
    header: string,
    fieldName: string,
    params: { id: number | string },
    hidden: boolean = false
  ) {
    super(ColumnType.AssetReportSelect, header, fieldName, hidden, params);
  }
}

@Component({
  selector: 'asset-report-select-column',
  templateUrl: './report-select-column.component.html',
  styleUrls: ['./report-select-column.scss'],
  providers: [MakorOrderAPIService, AssetApiService, AssetSourceService],
})
export class AssetReportSelectColumnComponent
  extends ReportSelectColumnComponent
  implements OnInit
{
  private result: AssetReport[];
  constructor(
    elRef: ElementRef,
    private assetApiService: AssetApiService,
    private orderService: MakorOrderAPIService,
    private assetService: AssetSourceService
  ) {
    super(elRef);
  }
  override loadList() {
    if (this.loaded) return;
    this.setLoadingList();
    this.assetService.getReportsList(this.id).subscribe((result) => {
      this.result = result;
      this.setDefaultList(
        result.map((x) => ({ id: x.actualName, text: x.displayName }))
      );
      this.loaded = true;
    });
  }
  override downloadReport() {
    let onDownloadSuccess = (result: File) => {
      this.downloading = false;
      result.fileName =
        this.options.find((e) => e.id === this.selectedOption)?.text ||
        result.fileName;
      this.downloadFile(result);
    };
    let onDownloadError = (error: any) => {
      console.log('Error', error);
      this.downloading = false;
    };
    this.downloading = true;

    this.assetService.getOrderId(this.id).subscribe((x) => {
      let reportType = this.getReportType(this.selectedOption);
      let reportAssetType = this.getReportAssetType(
        this.selectedOption
      ) as AssetReportEnum;

      if (reportType === InboundOrderReportTypeEnum.Makor) {
        this.orderService.getReport(x, this.selectedOption).subscribe({
          next: (result) => {
            onDownloadSuccess(result);
          },
          error: (error) => {
            onDownloadError(error);
          },
        });
      } else if (reportType === InboundOrderReportTypeEnum.Asset) {
        this.assetApiService
          .getCustomReport(this.id, reportAssetType, x)
          .subscribe({
            next: (result) => {
              onDownloadSuccess(result);
            },
            error: (error) => {
              onDownloadError(error);
            },
          });
      }
    });
  }

  private getReportType(option: string) {
    return this.result.find((e) => e.actualName === option)?.reportType;
  }

  private getReportAssetType(option: string) {
    return this.result.find((e) => e.actualName === option)?.assetReportEnum;
  }
}
