import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as uuid from 'uuid';
import moment, { Moment } from 'moment';

@Component({
  selector: 'app-custom-datepicker-range',
  templateUrl: './custom-datepicker-range.component.html',
  styleUrls: ['./custom-datepicker-range.component.scss'],
})
export class CustomDatepickerRangeComponent implements OnInit {
  @Input('id') id: string = uuid.v4();
  @Input('formGroup') formGroup: FormGroup;
  @Input('label') label: string;
  @Input('min-width') minWidth: number;

  @Output() onClearClick = new EventEmitter<any>();

  protected formControlStart: FormControl<Moment | null> =
    new FormControl<Moment | null>(moment());
  protected formControlEnd: FormControl<Moment | null> =
    new FormControl<Moment | null>(moment());

  protected hasError: boolean = false;

  ngOnInit() {
    this.formControlStart.patchValue(this.formGroup.controls['start'].value);
    this.formControlEnd.patchValue(this.formGroup.controls['end'].value);

    (this.formGroup.controls['start'] as FormControl).valueChanges.subscribe(
      (x) => {
        this.formControlStart.patchValue(x);
      }
    );
    (this.formGroup.controls['end'] as FormControl).valueChanges.subscribe(
      (x) => {
        this.formControlEnd.patchValue(x);
      }
    );

    this.formGroup.valueChanges.subscribe((x) => {
      this.hasError = false;
      if (
        (!!this.formGroup.controls['start'].errors &&
          !!this.formGroup.controls['end'].errors) ||
        !!this.formGroup.controls['start'].errors
      ) {
        this.hasError = true;
      }
    });
  }

  protected selectStart(value: any) {
    (this.formGroup.controls['start'] as FormControl).setValue(value.value);
  }
  protected selectEnd(value: any) {
    (this.formGroup.controls['end'] as FormControl).setValue(value.value);
  }
  protected clearValues() {
    this.formGroup.setValue({ start: null, end: null });
    this.onClearClick.emit();
  }
}
