import { createReducer, on } from '@ngrx/store';
import { Menu } from '@app/shared/model/menu.model';
import { setMenu } from './menu.action';

export const initialState: Menu = {
  canAddOrder: false,
  canAddUser: false,
  canAddReport: false,
  menuItems: [],
};

export const menuReducer = createReducer(
  initialState,
  on(setMenu, (state, { menu }) => menu)
);
