import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  ControlType,
  QuestionBase,
} from '@app/shared/dynamicForm/QuestionBase';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
})
export class DynamicFormComponent implements OnInit {
  protected formControl: FormControl;
  protected formGroup: FormGroup;
  protected controlType = ControlType;
  protected show: boolean = false;

  ngOnInit(): void {
    let formToCheck = this.form.controls[this.question.key];
    if (formToCheck instanceof FormGroup) {
      this.formGroup = this.form.controls[this.question.key] as FormGroup;
    } else {
      this.formControl = this.form.controls[this.question.key] as FormControl;
    }

    this.show = true;
  }

  @Input() question!: QuestionBase<any>;
  @Input() form!: FormGroup;
  get isValid() {
    return this.form.controls[this.question.key].valid;
  }
}
