import { Injectable } from '@angular/core';
import { PageSettings } from '@app/core/models/page-settings';

@Injectable()
export class TableContext {
  pageSettings: PageSettings;

  constructor() {
    this.pageSettings = {
      pageNumber: 0,
      pageSize: 10,
      sortBy: '',
      sortDirection: '',
    };
  }

  setPageSettings(pageSize: number, currentPage: number, totalCount?: number) {
    this.pageSettings.pageSize = pageSize;
    this.pageSettings.pageNumber = currentPage;
    if (totalCount) {
      this.pageSettings.totalCount = totalCount;
    }
    this.pageSettings.totalPages = this.pageSettings.totalCount! % pageSize;
  }

  setStartPage() {
    this.pageSettings.pageNumber = 0;
  }

  setSortSettings(sortBy: string, sortDirection: string) {
    this.pageSettings = {
      ...this.pageSettings,
      sortBy: sortBy,
      sortDirection: sortDirection,
    };
  }
}
