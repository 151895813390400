import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { File } from '@app/core/models/file.model';
import { BaseWebService } from '@app/core/services/base-web.service';
import { NewOrder } from '../models/new-order.model';
import { OrderDocumentDetails } from '../models/order-document-details.model';
import { OrderDocument } from '../models/order-document.model';
import { OrderReport } from '../models/order-report.model';
import { NewOrderResponse } from '../models/new-order-response.model';
import { CustomInboundOrderReportEnum } from '@app/core/enums/custom-inbound-order-report-enum';
import { NewOrderCreate } from '../models/new-order-create.model';
import { Note } from '../models/note.model';
import { AddNote } from '../models/add-note.model';
import { Observable, of } from 'rxjs';

@Injectable()
export class MakorOrderAPIService extends BaseWebService {

  
  
  
  constructor(private http: HttpClient) {
    super('makorservice/InboundOrders', http);
  }

  getListOfDocuments(orderId: number) {
    return this.getMethod<OrderDocument[]>(`${orderId}/documents`);
  }

   
  

  getDocumentDetails(docId: number) {
    return this.getMethod<OrderDocumentDetails>(`documents/${docId}`);
  }

  getListOfReports() {
    return this.getMethod<OrderReport[]>('reports');
  }

  getReport(orderId: number, name: string, format: string = 'pdf') {
    return this.getMethod<File>(`${orderId}/reports/${name}/${format}`);
  }
  getCustomReport(orderId: number, type: CustomInboundOrderReportEnum) {
    return this.getMethod<File>(`${orderId}/reports/${type}`);
  }

  addNewOrder(newOrderData: NewOrder) {
    
    return this.postMethod<NewOrderResponse>('', newOrderData);
  }
  assignFileToOrder(orderID : number,formData : FormData) {
    
    return this.postMethodNoHeaders<OrderDocument[]>(`files/${orderID}`, formData);
  }

  deleteOrderDocument(docId: number) {
    return this.deleteMethod(`documents/${docId}`);
  }

  addNewOrderV2(newOrderData: NewOrderCreate) {
    return this.postMethod<NewOrderResponse>('create', newOrderData);
  }
  getNotes(orderId : number){
    return this.getMethod<Note[]>(`${orderId}/notes`)
  }
  storeNotes(orderId : number,request : AddNote){
    return this.postMethod(`${orderId}/notes`,request)
  }
}
