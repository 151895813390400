import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BarSelectorComponent } from './components/bar-selector/bar-selector.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatGridListModule } from '@angular/material/grid-list';
import { ContentHeaderComponent } from './components/content-header/content-header.component';
import { CookieNoticeComponent } from './components/cookie-notice/cookie-notice.component';
import { CustomTableComponent } from './components/custom-table/custom-table.component';
import { ColumnHeaderComponent } from './components/custom-table/components/column-header/column-header.component';
import { ColumnContainerComponent } from './components/custom-table/components/column-container/column-container.component';
import { TextColumnComponent } from './components/custom-table/components/column-container/text-column.component';
import { DateColumnComponent } from './components/custom-table/components/column-container/date-column.component';
import { ColumnDirective } from './components/custom-table/components/column-container/column.directive';
import { ProgressColumnComponent } from './components/custom-table/components/column-container/progress-column.component';
import { LinkColumnComponent } from './components/custom-table/components/column-container/link-column.component';
import { RouterModule } from '@angular/router';
import { ColumnHeaderSettingsComponent } from './components/custom-table/components/column-header-settings/column-header-settings.component';
import { HeaderSettingsModalComponent } from './components/custom-table/components/column-header-settings/header-settings-modal.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonComponent } from './components/button/button.component';
import { NewsComponent } from './components/news-component/news.component';
import { NewsCardComponent } from './components/news-component/news-card/news-card.component';
import { CustomInputComponent } from './components/input/custom-input/custom-input.component';
import { CustomSelectComponent } from './components/input/custom-select/custom-select.component';
import { OrderReportSelectColumnComponent } from './components/custom-table/components/column-container/report-columns/order-report-select-column.component';
import { CustomAutocompleteComponent } from './components/input/custom-autocomplete/custom-autocomplete.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FilterDialogComponent } from './components/filter-dialog/filter-dialog.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { CustomDatepickerComponent } from './components/input/custom-datepicker/custom-datepicker.component';
import { CustomDatepickerRangeComponent } from './components/input/custom-datepicker-range/custom-datepicker-range.component';
import { DynamicFormComponent } from './components/dynamic-form/dynamic-form.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MenuColumnComponent } from './components/custom-table/components/column-container/menu-column.component';
import { WarnDialogComponent } from './components/warn-dialog/warn-dialog.component';
import { InputDialogComponent } from './components/Input-dialog/input-dialog.component';
import { BarSelectorService } from './services/bar-selector.service';
import { ColumHeaderCheckBoxComponent } from './components/custom-table/components/column-header-check-box/column-header-check-box';
import { CustomCheckBoxComponent } from './components/input/custom-check-box/custom-check-box.component';
import { LetDirective } from '@ngrx/component';
import { CheckBoxColumnComponent } from './components/custom-table/components/column-container/column-check-box.component';
import { SpinnerComponent } from './components/spinner/spinner/spinner.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { StoreModule } from '@ngrx/store';
import { HelpComponent } from './help/help.component';
import { IconColumnComponent } from './components/custom-table/components/column-container/icon-column.component';
import { CustomMultiSelectComponent } from './components/input/custom-multi-select/custom-multi-select.component';
import { HelpContactFormComponent } from './help/components/help-contact-form/help-contact-form.component';
import { HelpTextComponent } from './help/components/help-text/help-text.component';
import { CustomTextareaComponent } from './components/input/custom-textarea/custom-textarea.component';
import { FormsModule } from '@angular/forms';
import { DivisionSelectorComponent } from './components/division-selector/division-selector.component';
import { UploadFilesComponent } from './components/file-upload/file-upload.component';
import { DragDropFileUploadDirective } from '@app/modules/orders/directive/drag-drop-file-upload.directive';
import { MatInputModule } from '@angular/material/input';
import { UploadPanelComponent } from './components/file-upload/upload-panel/upload-panel.component';
import { FileUploadWithSendingComponent } from './components/file-upload/file-upload-with-sending.component';
import { UploadedFilesPanelComponent } from './components/file-upload/uploaded-files-panel/uploaded-files-panel.component';
import { AssetReportSelectColumnComponent } from './components/custom-table/components/column-container/report-columns/asset-report-select-column.component';

@NgModule({
  declarations: [
    BarSelectorComponent,
    ContentHeaderComponent,
    CookieNoticeComponent,
    CustomTableComponent,
    ColumnContainerComponent,
    TextColumnComponent,
    DateColumnComponent,
    ColumnDirective,
    ProgressColumnComponent,
    ColumnHeaderComponent,
    ColumHeaderCheckBoxComponent,
    LinkColumnComponent,
    CheckBoxColumnComponent,
    OrderReportSelectColumnComponent,
    AssetReportSelectColumnComponent,
    ColumnHeaderSettingsComponent,
    HeaderSettingsModalComponent,
    ButtonComponent,
    NewsComponent,
    NewsCardComponent,
    CustomInputComponent,
    CustomCheckBoxComponent,
    CustomSelectComponent,
    CustomAutocompleteComponent,
    FilterDialogComponent,
    CustomDatepickerComponent,
    CustomDatepickerRangeComponent,
    CustomMultiSelectComponent,
    DynamicFormComponent,
    MenuColumnComponent,
    WarnDialogComponent,
    InputDialogComponent,
    SpinnerComponent,
    HelpComponent,
    IconColumnComponent,
    CustomTextareaComponent,
    HelpContactFormComponent,
    HelpTextComponent,
    DivisionSelectorComponent,
    UploadFilesComponent,
    DragDropFileUploadDirective,
    UploadPanelComponent,
    FileUploadWithSendingComponent,
    UploadedFilesPanelComponent,
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    MatTableModule,
    MatProgressBarModule,
    MatPaginatorModule,
    MatDialogModule,
    MatSelectModule,
    MatCardModule,
    MatCheckboxModule,
    MatGridListModule,
    RouterModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    LetDirective,
    StoreModule,
    NgMultiSelectDropDownModule.forRoot(),
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
  ],
  exports: [
    CommonModule,
    BarSelectorComponent,
    DivisionSelectorComponent,
    MatFormFieldModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    ContentHeaderComponent,
    CookieNoticeComponent,
    CustomTableComponent,
    ReactiveFormsModule,
    ButtonComponent,
    NewsComponent,
    CustomInputComponent,
    CustomCheckBoxComponent,
    CustomSelectComponent,
    CustomAutocompleteComponent,
    MatAutocompleteModule,
    FilterDialogComponent,
    FilterDialogComponent,
    MatDatepickerModule,
    CustomDatepickerComponent,
    CustomDatepickerRangeComponent,
    MatProgressSpinnerModule,
    DynamicFormComponent,
    SpinnerComponent,
    HelpComponent,
    CustomMultiSelectComponent,
    HelpContactFormComponent,
    HelpTextComponent,
    UploadFilesComponent,
    DragDropFileUploadDirective,
    FileUploadWithSendingComponent,
    CustomTextareaComponent,
  ],
  providers: [BarSelectorService],
})
export class SharedModule {}
