<div style="display: flex">
  <select
    (click)="loadList()"
    (change)="selectedReport($event)"
    class="report-select"
  >
    <option *ngFor="let o of options" [value]="o.id">{{ o.text }}</option>
  </select>
  <div style="width: 20px">
    <mat-icon
      class="text-primary download-report"
      (click)="downloadReport()"
      [style.visibility]="
        !downloading && selectedOption !== '' ? 'visible' : 'hidden'
      "
      >cloud_download</mat-icon
    >
    <mat-spinner diameter="20" *ngIf="downloading"></mat-spinner>
  </div>
</div>
