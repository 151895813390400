<div class="custom-form-group">
  <label [for]="id" *ngIf="!!label" class="custom-label-input">{{
    label
  }}</label>
  <div class="custom-date-picker-wrapper">
    <input
      class="custom-date-picker"
      [class.--on-submit-error]="formControl.invalid"
      [class.--error]="hasErrors"
      [ngClass]="customClass"
      [formControl]="formControl"
      [placeholder]="placeholder"
      readonly
      [id]="id"
      [matDatepicker]="picker"
      matInput
    />
    <mat-datepicker-toggle
      *ngIf="!disabled"
      class="custom-date-picker-icon"
      matIconSuffix
      [for]="picker"
    ></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </div>
</div>
